@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:focus {
  outline: 0px !important;
  outline-offset: 0px !important;
  box-shadow: 0px 0px 0px 4px #3b82f640;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.blue-gradient {
  background: linear-gradient(
    180deg,
    #d4e5fc 5.07%,
    #e9f2ff 62.52%,
    #f2f7ff 123.75%
  );
}

.blue-gradient-mobile {
  @media screen and (max-width: 1280px) {
    background: linear-gradient(
      180deg,
      #d4e5fc 5.07%,
      #e9f2ff 62.52%,
      #f2f7ff 123.75%
    );
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 160px 24px 160px 24px;
  }
}

.option-shadow {
  box-shadow: 0px 32px 48px -16px #0000001a;
}

.tooltip::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: solid 8px #1f2937;
  border-left: solid 7px transparent;
  border-right: solid 7px transparent;
}

.warning-shadow {
  box-shadow: 0px 0px 0px 4px #f63b3b40;
}

.shadow-small {
  box-shadow: 0px 1px 2px -1px #0000001a;
  box-shadow: 0px 1px 3px 0px #0000001a;
}

.shadow-medium {
  box-shadow: 0px 2px 4px -2px #0000000d;
  box-shadow: 0px 4px 6px -1px #0000001a;
}

.shadow-large {
  box-shadow: 0px 4px 6px 0px #0000000d;
  box-shadow: 0px 10px 15px -3px #0000001a;
}

.shadow-blue:hover {
  box-shadow: 0px 0px 0px 4px #d4e5fc;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none !important;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.calender-custom-selected:not([disabled]) {
  font-weight: bold;
  background-color: #61bed9;
  color: #fff;
  border-radius: 20px;
  padding: 4px;
}
.calender-custom-selected:hover:not([disabled]) {
  background-color: #61bed9;
  color: #fff;
}
.calender-custom-today {
  font-weight: bold;
  font-size: 140%;
}

button[name='previous-month'] .rdp-nav_icon path {
  fill: #b9b9b9;
}
button[name='next-month'] .rdp-nav_icon path {
  fill: #b9b9b9;
}

.rdp-day:hover {
  color: #fff;
  background-color: #61bed9 !important;
}

.custom-toast {
  background-color: #e8f5e9 !important;
  color: #388e3c !important;
  font-family: 'Inter';
}

.custom-toast-error {
  background-color: #fce4ec !important;
  color: #ad1457 !important;
  font-family: 'Inter';
}

.Toastify__toast-container {
  max-width: 1260px !important;
  width: 100% !important;
  margin: 0 auto !important;
  margin-top: 20px !important;
}

.cover-all {
  z-index: 9999 !important;
}

.messaging-modal {
  height: calc(100vh - 24%);
  overflow-y: scroll;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.pale-gradient {
  background: linear-gradient(0deg, #f8fbff, #f8fbff),
    radial-gradient(
      53.7% 85.66% at 37.72% 23.22%,
      #f2f3ff 28.61%,
      rgba(242, 243, 255, 0) 100%
    );
}

.radial-gradient {
  background: linear-gradient(0deg, #edf0f7, #edf0f7),
    radial-gradient(
      53.7% 85.66% at 37.72% 23.22%,
      #f2f3ff 28.61%,
      rgba(242, 243, 255, 0) 100%
    );
}

.bell {
  display: block;
  width: 16px;
  height: 16px;
  font-size: 16px;
  color: #fff;
  -webkit-animation: ring 4s 0.7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s 0.7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s 0.7s ease-in-out infinite;
  transform-origin: 50% 4px;
}
.checkmark {
  display: inline-block;
  font-size: 16px;
  color: #fff;
  animation: bounce 2s ease-in-out 1;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-6px);
  }
  60% {
    transform: translateY(-2px);
  }
}

.menu {
  opacity: 0;
  transition: opacity 0.1s ease-in-out;
  pointer-events: none;
}

@media only screen and (min-width: 1024px) {
  .menu {
    transform: translateX(100%);
    pointer-events: none;
    opacity: 0;
    transition:
      transform 0.3s ease-in-out,
      opacity 0.3s ease-in-out;
  }
}

.menu.active {
  transform: translateX(0);
  opacity: 1;
  pointer-events: auto;
}

.blue-shadow {
  box-shadow: 0px 0px 0px 4px #e2f3f8;
}

.no-scroll {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  width: 100%;
}

.custom-checkbox input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url('assets/img/checkbox-gray-empty.svg'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  border-color: transparent;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}

/* Custom checkmark for checked state */
.custom-checkbox input[type='checkbox']:checked {
  background-image: url('assets/img/checkbox-blue-full.svg'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  border-color: transparent;
}

.card-hover {
  box-shadow: 0px 5px 10px -4px #61bed924;
  box-shadow: 0px 8px 28px -6px #61bed91f;
}

.light-blue-shadow {
  box-shadow: 0px 4px 11px -1px #0a0a0a0a;
}

input.PhoneInputInput {
  width: 100%;
}

.card-shadow {
  box-shadow: 0px 1px 2px -1px #0000001a;
  box-shadow: 0px 1px 3px 0px #0000001a;
}

#payment-form {
  width: 100%;
  align-self: center;
  border-radius: 4px;
  padding: 40px;
  margin: auto; /* Center vertically and horizontally */
}

/* Media query for small screens */
@media (max-width: 768px) {
  #payment-form {
    width: 100%; /* Take up the full screen width */
    padding: 16px;
  }
}

#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
#payment-form button {
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

#payment-form button:hover {
  filter: contrast(115%);
}

#payment-form button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #0055de;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #0055de;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

/* dynamic payment methods annotation */
#dpm-annotation {
  align-self: center;
  color: #353a44;
  width: 30vw;
  min-width: 500px;
  line-height: 20px;
  margin-bottom: 20px;
}

#dpm-integration-checker {
  display: inline;
  color: #533afd;
}

/* Payment status page */
#payment-status {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 30px;
  width: 30vw;
  min-width: 500px;
  min-height: 380px;
  align-self: center;
  box-shadow:
    0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1),
    0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
  opacity: 0;
  animation: fadeInAnimation 1s ease forwards;
  margin-top: auto;
  margin-bottom: auto;
}

#status-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

#details-table {
  overflow-x: auto;
  width: 100%;
}

#view-details {
  color: #0055de;
}

#retry-button {
  text-align: center;
  background: #0055de;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes fadeInAnimation {
  to {
    opacity: 1;
  }
}

.custom-input {
  width: 100%;
  padding: 12px;
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #7c878e;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition:
    border-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
}

.custom-input:focus {
  border-color: #0370de;
  box-shadow: 0px 0px 0px 2px #c6daf6;
  outline: none;
}

.gold-gradient {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.gold-gradient::before {
  content: '';
  position: absolute;
  inset: 0;
  padding: 2px; /* Adjust for border thickness */
  background: linear-gradient(
    90deg,
    #ff8f00 0%,
    #ff8f00 12.5%,
    #ff8f00 25%,
    #fcc357 37.5%,
    #fcc357 50%,
    #fcc357 62.5%,
    #ff8f00 75%,
    #ff8f00 87.5%,
    #ffa000 100%
  );
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask-composite: destination-out;
  border-radius: inherit;
}

.trending-img-shadow {
  box-shadow: 0px 1.66px 3.31px -1.66px #0000000d;
  box-shadow: 0px 3.31px 4.97px -0.83px #0000001a;
}
